<template>
  <section class="ds s-py-45 s-py-xl-75 c-gutter-60">
    <div class="container">
      <div class="row">
        <main class="col-lg-12">
          <article>
            <!-- <header class="entry-header">
              <h1 class="entry-title">{{ getCurrentCategory.subtitle }}</h1>
            </header>-->
            <!-- .entry-header -->
            <div class="entry-content">
              <div class="woocommerce">
                <div class="woocommerce-MyAccount-content">
                  <form id="child-form" role="form" style="display: block">
                    <p class="mm-font __mm" align="center">
                      အောက်ပါပေးထားသော Form အချက်အလက်များကို ဖြည့်ပေးပါ
                    </p>
                    <label class="form-group">
                      <span class="mm-font __mm">အမည်</span>
                      <input
                        type="text"
                        class="form-control"
                        name="name"
                        v-model="direct.name"
                        required
                      />
                    </label>
                    <label class="form-group">
                      <span class="mm-font __mm">မွေးသက္ကရာဇ်</span>
                      <!-- <input
                        type="date"
                        class="form-control"
                        name="birth_date"
                        v-model="direct.birth_date"
                        required
                      /> -->

                      <md-datepicker
                        value-format="YYYY-MM-DD"
                        class="input-container"
                        v-model="direct.birth_date"
                      >
                      </md-datepicker>
                    </label>
                    <label class="form-group">
                      <span class="mm-font __mm">နေ့နံ</span>
                      <select
                        class="mm-font __mm"
                        name="nyih_nan"
                        v-model="direct.nyih_nan"
                        required
                      >
                        <option value="တနင်္ဂနွေ" class="__mm">
                          တနင်္ဂနွေ
                        </option>
                        <option value="တနင်္လာ" class="__mm">တနင်္လာ</option>
                        <option value="အင်္ဂါ" class="__mm">အင်္ဂါ</option>
                        <option value="ဗုဒ္ဓဟူး" class="__mm">ဗုဒ္ဓဟူး</option>
                        <option value="ရာဟု" class="__mm">ရာဟု</option>

                        <option value="ကြာသပတေး" class="__mm">ကြာသပတေး</option>
                        <option value="သောကြာ" class="__mm">သောကြာ</option>
                        <option value="စနေ" class="__mm">စနေ</option>
                      </select>
                    </label>
                    <input
                      type="radio"
                      name="marital_status"
                      value="Yes"
                      v-model="direct.marital_status"
                      required
                    />
                    <span for class="mm-font __mm">အိမ်ထောင်ရှိ</span>
                    <input
                      type="radio"
                      name="marital_status"
                      value="No"
                      v-model="direct.marital_status"
                      required
                    />
                    <span for class="mm-font __mm">အိမ်ထောင်မရှိ</span>
                    <br />
                    <br />
                    <!-- <label class="form-group">
                      <span for class="mm-font __mm">နေထိုင်သည့်မြို့</span>
                      <input
                        type="text"
                        class="form-control"
                        name="address"
                        v-model="direct.address"
                        required
                      />
                    </label> -->
                    <label class="form-group">
                      <span for class="mm-font __mm">နေရပ်လိပ်စာ</span>
                      <div
                        style="
                          display: grid;
                          grid-template-columns: 1fr 1fr;
                          gap: 10px;
                        "
                      >
                        <select
                          v-model="direct.address_division"
                          required
                          class="mm-font __mm"
                          @change="handleDivisionChange"
                        >
                          <option value="" selected hidden>
                            ပြည်နယ်/တိုင်း
                          </option>
                          <option
                            v-for="d in divisions"
                            :data-nrc="d.nrc_id"
                            :value="d.name"
                            :key="d.name"
                            class="__mm"
                          >
                            {{ d.name }}
                          </option>
                        </select>
                        <select
                          v-model="direct.address_township"
                          required
                          class="mm-font __mm"
                        >
                          <option value="" selected hidden>မြို့နယ်</option>
                          <option
                            value=""
                            disabled
                            v-if="townships.length == 0"
                          >
                            တိုင်း/ ပြည်နယ် ကိုအရင်ရွေးချယ်ပါ
                          </option>
                          <option
                            v-for="t in townships"
                            :value="t.name"
                            :key="t.name"
                            class="__mm"
                          >
                            {{ t.name }}
                          </option>
                        </select>
                      </div>
                      <input
                        type="text"
                        class="mm-font __mm form-control"
                        placeholder="ရပ်ကွက်/လမ်း အသေးစိတ်"
                        v-model="direct.address"
                        required
                      />
                    </label>
                    <input
                      type="radio"
                      name="gender"
                      value="Male"
                      v-model="direct.gender"
                      required
                    />
                    <span for class="mm-font __mm">ကျား</span>
                    <input
                      type="radio"
                      name="gender"
                      value="Female"
                      v-model="direct.gender"
                      required
                    />
                    <span for class="mm-font __mm">မ</span>
                    <br />
                    <br />
                    <!-- <br />
                    <br /> -->
                    <label class="form-group">
                      <span class="mm-font __mm">ဗေဒင်ဆရာ</span>
                      <select
                        name="baydin_sayar"
                        class="mm-font __mm"
                        v-model="direct.baydin_sayar"
                        required
                      >
                        <option value="" class="mm-font __mm" selected hidden>
                          ဗေဒင်ဆရာ ရွေးချယ်ပါ...
                        </option>
                        <!-- <option value="ဆရာမ ဒေါက်တာစောယုနွယ်">
                          ဆရာမ ဒေါက်တာစောယုနွယ်
                        </option>
                        <option value="ဆရာမ ယမင်းအေး">ဆရာမ ယမင်းအေး</option>
                        <option value="ဆရာလှိုင်ဘွားဦး">ဆရာလှိုင်ဘွားဦး</option>
                        <option value="ဆရာကျော်ဇင်သွေး">ဆရာကျော်ဇင်သွေး</option>
                        <option value="ဆရာသာထူးစံ">ဆရာသာထူးစံ</option>
                        <option value="ဆရာကောင်းဇော်သန့်">
                          ဆရာကောင်းဇော်သန့်
                        </option> -->

                        <!-- old -->
                        <option value="ဆရာမ သန့်ထားဆု">ဆရာမ သန့်ထားဆု</option>
                        <option value="ဆရာ ကျော်ဇောဟန်" class="__mm">
                          ဆရာ ကျော်ဇောဟန်
                        </option>
                        <option value="ဆရာ မင်းသိမ်းခိုင်" class="__mm">
                          ဆရာ မင်းသိမ်းခိုင်
                        </option>
                        <option value="ဆရာမ စောလှနွယ်နီ" class="__mm">
                          ဆရာမ စောလှနွယ်နီ
                        </option>
                        <!-- <option value="ဆရာ မိုးမင်းသုခ" class="__mm">
                          ဆရာ မိုးမင်းသုခ
                        </option> -->
                        <option value="ဆရာမ စောယုထွေး" class="__mm">
                          ဆရာမ စောယုထွေး
                        </option>
                        <option value="ဆရာမ ဝင့်ယမုံဦး" class="__mm">
                          ဆရာမ ဝင့်ယမုံဦး
                        </option>
                      </select>
                    </label>
                    <label class="form-group">
                      <span for class="mm-font __mm"
                        >ဆက်သွယ်ရမည့်ဖုန်းနံပါတ်</span
                      >
                      <input
                        type="text"
                        class="form-control"
                        name="phone_number"
                        placeholder="09xxxxxxxxx"
                        v-model="direct.phone_number"
                        @input="onlyDigit($event)"
                        required
                      />
                    </label>

                    <!-- <p class="mm-font __mm">မေးလိုသည့်ကဏ္ဍကို ရွေးချယ်ပါ။</p> -->

                    <!-- <div
                      class="container options"
                      ref="input"
                      v-if="!isNotBaydinSayr"
                    >
                      <label
                        class="form-check-label customcheck"
                        v-for="type in getServices[0].services"
                        :key="type.id"
                      >
                        <input
                          class="form-check-input"
                          type="checkbox"
                          :value="type.name"
                          name="services[]"
                          v-model="direct.services"
                          @click="toggle(type)"
                        />
                        <span class="checkmark"></span>
                        <span class="mm-font __mm">{{ type.mm_name }}</span>
                      </label>
                    </div> -->
                    <br />
                    <br />
                    <p class="mm-font __mm">
                      မေးမြန်းလိုသည့်အကြာင်းအရာကိုရေးပါ။
                    </p>
                    <label class="form-group">
                      <textarea
                        name="about"
                        class="mm-font test"
                        rows="10"
                        cols="35"
                        placeholder
                        style="color: black"
                        v-model="direct.about"
                        required
                      ></textarea>
                    </label>

                    <p class="mm-font" id="price">
                      ဉာဏ်ပူဇော်ခ ၇၀၀၀ ကျပ် ဖြစ်ပါသည်။
                      ငွေပေးချေမှုအောင်မြင်ပြီးပါက နှစ်ရက်အတွင်း
                      ပြန်လည်ဖြေကြားပေးပါမည်။
                    </p>
                    <p>
                      <input
                        type="submit"
                        @click.prevent="paymentProvider()"
                        :disabled="disabled"
                        class="woocommerce-Button button mm-font __mm"
                        name="save_account_details"
                        value="အတည်ပြုမည်"
                      />
                    </p>
                  </form>
                </div>
              </div>
            </div>
            <!-- .entry-content -->
          </article>
        </main>
      </div>
    </div>
  </section>
</template>
<style>
.input-container {
  display: flex;
  border: 1px solid #000;
  background-color: #f2eff7;
  color: #3e1e68;
  border-radius: 25px;
  padding: 8px 0px 0px 10px;
}
.md-field.md-theme-default:after {
  background-color: rgba(0, 0, 0, 0);
}
.md-button.md-theme-default {
  margin: 0px 16px 0px 0px;
}
</style>

<script>
// import types from "@core/i18n/resource/direct_types.json";
// import types from "@core/i18n/resource/test.json";
import { mapGetters } from "vuex";
import { Horo, HTTP } from "@core/lib/http-common";
import { getDivisions, getTownships } from "../js/addressInfo";
import { getFormData, checkFormModel } from "@core/lib/form-data";
import { makePayment, onePay, citizenPay } from "../js/payment";
import moment from "moment";

export default {
  name: `Special`,
  data() {
    return {
      types: [],
      selected: {},
      disabled: false,
      isNotBaydinSayr: true,
      getServices: [],
      actionBtnMethod: "",
      onepayPhoneNumber: "",
      divisions: [],
      townships: [],
      direct: {
        name: "",
        birth_date: moment(new Date("01/01/1930")).format("YYYY-MM-DD"),
        nyih_nan: "",
        address: "",
        address_division: "",
        address_township: "",
        phone_number: "",
        order_id: "0",
        gender: "",
        user_id: "0",
        services: [
          "အချစ်ရေး",
          "အိမ်ထောင်ရေး",
          "ကိုယ်ပိုင်စီးပွားရေး",
          "အလုပ်အကိုင်",
          "ပညာရေး",
          "ကျန်းမာရေး",
          "ငွေရေးကြေးရေး",
          "လူမှုရေးအထွေထွေ",
          "ထီပေါက်ကိန်း",
        ],
        about: "",
        baydin_sayar: "",
        marital_status: "",
        price: 7000,
      },
    };
  },
  async created() {
    // this.types = types;
    this.divisions = await getDivisions();
    this.actionBtnMethod = this.$store.state.paymentName;
    this.onepayPhoneNumber =
      this.$store.state.paymentName == "onepay"
        ? this.$store.state.onepayPhoneNumber
        : "";

    // if (this.$store.state.paymentName == " ") {
    //   this.types = types[0].getService;
    // } else {
    //   types.map((v) => {
    //     if (v.payment != " ") {
    //       if (v.payment == this.$store.state.paymentName) {
    //         this.types = v.getService;
    //       }
    //     } else {
    //       this.types = types[0].getService;
    //     }
    //   });
    // }
  },
  mounted() {
    // this.types.map((type) => {
    //   type.services.map((service) => {
    //     if (service.name == this.categoryName) {
    //       this.selected[service.name] = true;
    //     } else {
    //       this.selected[service.name] = false;
    //     }
    //   });
    // });
  },

  computed: {
    categoryName() {
      return this.$route.params.categoryName;
    },
    ...mapGetters({
      getUserToken: "userStore/getUserToken",
      getResponse: "userStore/getResponse",
    }),
  },
  methods: {
    async handleDivisionChange(e) {
      let nrc_id = e.target.options[e.target.selectedIndex].dataset.nrc;
      this.townships = await getTownships(nrc_id);
    },

    onlyDigit($event) {
      let key = $event.keyCode || $event.charCode;
      if ($event.target.value.length > 11 && key != 8) {
        $event.target.value = $event.target.value.substr(
          0,
          $event.target.value.length - 1
        );
      }

      $event.target.value = $event.target.value.replace(/[^\d]/gi, "");
    },

    // getService(name) {
    //   this.direct.price = 0;
    //   for (const key in this.selected) {
    //     this.selected[key] = false;
    //   }

    //   document
    //     .querySelectorAll("input[type=checkbox]")
    //     .forEach((el) => (el.checked = false));

    //   this.isNotBaydinSayr = this.direct.baydin_sayar == " " ? true : false;
    //   if (name != " ") {
    //     this.getServices = this.types.filter((v, i) => {
    //       if (name == v.name) {
    //         if (i != 0) {
    //           return i;
    //         } else {
    //           return this.types[0];
    //         }
    //       }
    //     });
    //   } else {
    //     this.isNotBaydinSayr = true;
    //   }
    // },
    // toggle(type) {
    //   if (!this.selected[type.name]) {
    //     this.direct.price += type.price;
    //     this.direct.services.push(type.name);
    //   } else {
    //     this.direct.price -= type.price;
    //     this.direct.services = this.direct.services.filter(
    //       (item) => item !== type.name
    //     );
    //   }
    //   this.selected[type.name] = !this.selected[type.name];
    // },
    // Provider is just a name. I didn't follow provider pattern rules
    paymentProvider() {
      let getPaymentName = this.actionBtnMethod;
      switch (getPaymentName) {
        case "mpitesan":
          this.makePayment();
          break;
        case "onepay":
          this.onePayProvider();
          break;
        case "citizenpay":
          this.citizenPayProvicer();
          break;
        case "bppay":
          this.bpPay();
          break;
        default:
          this.precreate();
          break;
      }
    },

    async citizenPayProvicer() {
      if (checkFormModel(this.direct)) {
        this.disabled = true;

        let formdata = getFormData(this.direct);
        formdata.append("user_phone", this.direct.phone_number);
        let payment_method =
          this.$store.state.paymentName == " "
            ? "kbz"
            : this.$store.state.paymentName;
        formdata.append("payment_method", payment_method);
        let cdata = {
          category_id: 55555,
          amount: this.direct.price,
          category_name: "direct",
        };
        await citizenPay([
          "1875/direct/horoscope",
          formdata,
          cdata,
          this.$store.state.backendUrl,
          this.direct.price,
        ])
          .then((data) => {
            if (data.status == 200) {
              window.location.href = data.paymentGatway;
            } else {
              this.$swal("Oops", "Something went wrong!", "error");
            }
          })
          .catch(() => {
            this.$swal("Oops", "Something went wrong!", "error");
          });
      } else {
        this.$swal("Oops", "You need to fill all fields", "error");
      }
    },

    async onePayProvider() {
      // console.log("ok");
      if (checkFormModel(this.direct)) {
        this.disabled = true;

        let formdata = getFormData(this.direct);
        formdata.append("user_phone", this.direct.phone_number);
        let payment_method =
          this.$store.state.paymentName == " "
            ? "kbz"
            : this.$store.state.paymentName;
        formdata.append("payment_method", payment_method);
        let cdata = {
          category_id: 55555,
          amount: this.direct.price,
          category_name: "direct",
          phone_number:
            this.onepayPhoneNumber == ""
              ? this.direct.phone_number
              : this.onepayPhoneNumber,
        };
        await onePay([
          "1875/direct/horoscope",
          formdata,
          cdata,
          this.$store.state.backendUrl,
          this.direct.price,
        ])
          .then((data) => {
            if (data.status == 200) {
              // console.log(data);
              this.$store.state.order_id = data.order_id;
              this.$store.state.amount = data.amount;
            } else {
              this.$swal("Oops", "Something went wrong!", "error");
            }
          })
          .catch(() => {
            this.$swal("Oops", "Something went wrong!", "error");
          });
      } else {
        this.$swal("Oops", "You need to fill all fields", "error");
      }
    },

    async bpPay() {
      if (checkFormModel(this.direct)) {
        this.disabled = true;

        let formdata = getFormData(this.direct);
        formdata.append("user_phone", this.direct.phone_number);
        let payment_method =
          this.$store.state.paymentName == " "
            ? "kbz"
            : this.$store.state.paymentName;
        formdata.append("payment_method", payment_method);
        let cdata = {
          category_id: 55555,
          amount: this.direct.price,
          category_name: "direct",
        };
        await makePayment([
          "1875/direct/horoscope",
          formdata,
          cdata,
          this.$store.state.backendUrl,
          this.direct.price,
        ])
          .then((data) => {
            if (data.status == 200) {
              // console.log(data);
              this.$store.state.order_id = data.order_id;
              this.$store.state.amount = data.amount;
              this.$router.push({
                name: "paymentPortalV3",
              });
            } else {
              this.$swal("Oops", "Something went wrong!", "error");
            }
          })
          .catch(() => {
            this.$swal("Oops", "Something went wrong!", "error");
          });
      } else {
        this.$swal("Oops", "You need to fill all fields", "error");
      }
    },

    async makePayment() {
      if (checkFormModel(this.direct)) {
        this.disabled = true;

        let formdata = getFormData(this.direct);
        formdata.append("user_phone", this.direct.phone_number);
        let payment_method =
          this.$store.state.paymentName == " "
            ? "kbz"
            : this.$store.state.paymentName;
        formdata.append("payment_method", payment_method);
        let cdata = {
          category_id: 55555,
          amount: this.direct.price,
          category_name: "direct",
        };
        await makePayment([
          "1875/direct/horoscope",
          formdata,
          cdata,
          this.$store.state.backendUrl,
          this.direct.price,
        ])
          .then((data) => {
            if (data.status == 200) {
              // console.log(data);
              this.$store.state.order_id = data.order_id;
              this.$store.state.amount = data.amount;
              this.$router.push({
                name: "paymentPortal",
              });
            } else {
              this.$swal("Oops", "Something went wrong!", "error");
            }
          })
          .catch(() => {
            this.$swal("Oops", "Something went wrong!", "error");
          });
      } else {
        this.$swal("Oops", "You need to fill all fields", "error");
      }
    },

    precreate() {
      if (checkFormModel(this.direct)) {
        this.disabled = true;
        HTTP.defaults.headers.Authorization = `Bearer ${this.getUserToken}`;
        HTTP.post("subscription/precreate", {
          category_id: 55555,
          amount: this.direct.price,
          category_name: "direct",
        })
          .then((response) => {
            const res = response.data;
            this.direct.order_id = res.order_id;
            this.direct.user_id = res.user_id;
            let formdata = getFormData(this.direct);
            formdata.set('price', res.amount);
            formdata.append(
              "user_phone",
              this.getResponse.customer_info.msisdn
            );
            Horo.post("1875/direct/horoscope", formdata).then((response) => {
              this.res_status = response.data.status;
              this.kbzpay.startPay(
                res.prepay_id,
                res.order_info,
                res.sign_app,
                ({ resultCode, lang }) => {
                  if (resultCode == 1) {
                    if (lang == "en") {
                      this.$swal("Success", "Your Purchase Success", "success");
                      this.$router.push({ name: "home" });
                    }
                    if (lang == "my") {
                      this.$swal("အောင်မြင်ပါသည်", "ဝယ်ယူပြီးပါပြီ", "success");
                      this.$router.push({ name: "home" });
                    }
                  }
                  if (resultCode == 2) {
                    if (lang == "en") {
                      this.$swal("Failed", "Your Purchase Failed", "error");
                      this.disabled = false;
                    }
                    if (lang == "my") {
                      this.$swal("မအောင်မြင်ပါ", "ထပ်မံကြိုးစားပေးပါ", "error");
                      this.disabled = false;
                    }
                  }
                },
                () => {
                  this.$swal("Failed", "Your Purchase Failed", "error");
                  this.disabled = false;
                }
              );
            });
          })
          .catch((error) => {
            console.log(error);
            this.message = "Something went wrong";
          });
      } else {
        this.$swal("Oops", "You need to fill all fields", "error");
      }
    },
  },
};
</script>

<style scoped>
.form-group {
  display: flex !important;
  flex-direction: column;
  gap: 5px;
}

.mm-font {
  font-family: Unicode;
}

input[type="radio"] {
  display: inline;
  margin-top: 8px;
  margin-left: 3px;
}
input[type="checkbox"] {
  display: inline;
  margin-top: 8px;
}

input[type="text"]::placeholder {
  color: grey;
}

label {
  display: block;
}
</style>
