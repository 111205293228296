<template>
  <section class="ds s-py-45 s-py-xl-75 c-gutter-60">
    <!-- <section class="ds s-py-90 s-py-xl-150 c-gutter-60"> -->
    <div class="container">
      <div class="row">
        <main class="col-lg-12">
          <article>
            <!-- <header class="entry-header">
              <h1 class="entry-title">{{ getCurrentCategory.subtitle }}</h1>
            </header>-->
            <!-- .entry-header -->
            <div class="entry-content">
              <div class="woocommerce">
                <div class="woocommerce-MyAccount-content">
                  <form
                    id="child-form"
                    role="form"
                    style="display: block"
                    v-if="getCurrentCategory.id === 275"
                  >
                    <!-- <h3
                      class="mm-font __mm"
                      align="center"
                    >{{ getCurrentCategory.title }}</h3> -->
                    <p class="mm-font __mm" align="center">
                      အောက်ပါပေးထားသော Form အချက်အလက်များကို ဖြည့်ပေးပါ
                    </p>

                    <label class="mm-font __mm">အမျိုးသမီး</label>
                    <br />
                    <br />
                    <label class="form-group">
                      <span class="mm-font __mm __mm">အမည်</span>
                      <input
                        type="text"
                        class="form-control"
                        name="g_name"
                        v-model="love.g_name"
                        required
                      />
                    </label>
                    <label class="form-group mm-font">
                      <span class="mm-font __mm">မွေးနေ့</span>
                      <select
                        name="g_birth_day"
                        v-model="love.g_birth_day"
                        class="mm-font __mm"
                        required
                      >
                        <option value="တနင်္ဂနွေ" class="__mm">
                          တနင်္ဂနွေ
                        </option>
                        <option value="တနင်္လာ" class="__mm">တနင်္လာ</option>
                        <option value="အင်္ဂါ" class="__mm">အင်္ဂါ</option>
                        <option value="ဗုဒ္ဓဟူး" class="__mm">ဗုဒ္ဓဟူး</option>
                        <option value="ရာဟု" class="__mm">ရာဟု</option>

                        <option value="ကြာသပတေး" class="__mm">ကြာသပတေး</option>
                        <option value="သောကြာ" class="__mm">သောကြာ</option>
                        <option value="စနေ" class="__mm">စနေ</option>
                      </select>
                    </label>
                    <label class="form-group">
                      <span class="mm-font __mm">မွေးသက္ကရာဇ်</span>

                      <md-datepicker
                        v-model="love.g_birth_date"
                        value-format="YYYY-MM-DD"
                        class="input-container"
                        required
                      >
                        <!-- <label>Select date</label> -->
                      </md-datepicker>
                    </label>
                    <!-- <label class="form-group">
                      <span for class="mm-font __mm">နေရပ်လိပ်စာ</span>
                      <input
                        type="text"
                        class="form-control"
                        name="g_address"
                        v-model="love.g_address"
                        required
                      />
                    </label> -->
                    <label class="form-group">
                      <span for class="mm-font __mm">နေရပ်လိပ်စာ</span>
                      <div
                        style="
                          display: grid;
                          grid-template-columns: 1fr 1fr;
                          gap: 10px;
                        "
                      >
                        <select
                          v-model="love.g_address_division"
                          required
                          class="mm-font __mm"
                          @change="handleDivisionChange($event, '1')"
                        >
                          <option value="" selected hidden>
                            ပြည်နယ်/တိုင်း
                          </option>
                          <option
                            v-for="d in divisions1"
                            :data-nrc="d.nrc_id"
                            :value="d.name"
                            :key="d.name"
                            class="__mm"
                          >
                            {{ d.name }}
                          </option>
                        </select>
                        <select
                          v-model="love.g_address_township"
                          required
                          class="mm-font __mm"
                        >
                          <option value="" selected hidden>မြို့နယ်</option>
                          <option
                            value=""
                            disabled
                            v-if="townships1.length == 0"
                          >
                            တိုင်း/ ပြည်နယ် ကိုအရင်ရွေးချယ်ပါ
                          </option>
                          <option
                            v-for="t in townships1"
                            :value="t.name"
                            :key="t.name"
                            class="__mm"
                          >
                            {{ t.name }}
                          </option>
                        </select>
                      </div>
                      <input
                        type="text"
                        class="mm-font __mm form-control"
                        placeholder="ရပ်ကွက်/လမ်း အသေးစိတ် "
                        v-model="love.g_address"
                        required
                      />
                    </label>

                    <p style="font-size: 30px">
                      <i class="fa fa-heart" aria-hidden="true"></i>
                    </p>
                    <label class="mm-font __mm">အမျိုးသား</label>
                    <br />
                    <br />
                    <label class="form-group">
                      <span class="mm-font __mm">အမည်</span>
                      <input
                        type="text"
                        class="form-control"
                        name="b_name"
                        v-model="love.b_name"
                        required
                      />
                    </label>
                    <label class="form-group">
                      <span class="mm-font __mm">မွေးနေ့</span>
                      <select
                        name="b_birth_day"
                        v-model="love.b_birth_day"
                        class="mm-font __mm"
                        required
                      >
                        <option value="တနင်္ဂနွေ" class="__mm">
                          တနင်္ဂနွေ
                        </option>
                        <option value="တနင်္လာ" class="__mm">တနင်္လာ</option>
                        <option value="အင်္ဂါ" class="__mm">အင်္ဂါ</option>
                        <option value="ဗုဒ္ဓဟူး" class="__mm">ဗုဒ္ဓဟူး</option>
                        <option value="ရာဟု" class="__mm">ရာဟု</option>

                        <option value="ကြာသပတေး" class="__mm">ကြာသပတေး</option>
                        <option value="သောကြာ" class="__mm">သောကြာ</option>
                        <option value="စနေ" class="__mm">စနေ</option>
                      </select>
                    </label>
                    <label class="form-group">
                      <span class="mm-font __mm">မွေးသက္ကရာဇ်</span>
                      <!-- <input
                        type="date"
                        class="form-control"
                        name="b_birth_date"
                        v-model="love.b_birth_date"
                        required
                      /> -->
                      <md-datepicker
                        v-model="love.b_birth_date"
                        value-format="YYYY-MM-DD"
                        class="input-container"
                        required
                      >
                        <!-- <label>Select date</label> -->
                      </md-datepicker>
                    </label>
                    <!-- <label class="form-group">
                      <span for class="mm-font __mm">နေရပ်လိပ်စာ</span>
                      <input
                        type="text"
                        class="form-control"
                        name="b_address"
                        v-model="love.b_address"
                        required
                      />
                    </label> -->
                    <label class="form-group">
                      <span for class="mm-font __mm">နေရပ်လိပ်စာ</span>
                      <div
                        style="
                          display: grid;
                          grid-template-columns: 1fr 1fr;
                          gap: 10px;
                        "
                      >
                        <select
                          v-model="love.b_address_division"
                          required
                          class="mm-font __mm"
                          @change="handleDivisionChange($event, '2')"
                        >
                          <option value="" selected hidden>
                            ပြည်နယ်/တိုင်း
                          </option>
                          <option
                            v-for="d in divisions2"
                            :data-nrc="d.nrc_id"
                            :value="d.name"
                            :key="d.name"
                            class="__mm"
                          >
                            {{ d.name }}
                          </option>
                        </select>
                        <select
                          v-model="love.b_address_township"
                          required
                          class="mm-font __mm"
                        >
                          <option value="" selected hidden>မြို့နယ်</option>
                          <option
                            value=""
                            disabled
                            v-if="townships2.length == 0"
                          >
                            တိုင်း/ ပြည်နယ် ကိုအရင်ရွေးချယ်ပါ
                          </option>
                          <option
                            v-for="t in townships2"
                            :value="t.name"
                            :key="t.name"
                            class="__mm"
                          >
                            {{ t.name }}
                          </option>
                        </select>
                      </div>
                      <input
                        type="text"
                        class="mm-font __mm form-control"
                        placeholder="ရပ်ကွက်/လမ်း  အသေးစိတ်"
                        v-model="love.b_address"
                        required
                      />
                    </label>

                    <label class="form-group">
                      <span for class="mm-font __mm"
                        >ဆက်သွယ်ရမည့်ဖုန်းနံပါတ်</span
                      >
                      <input
                        type="text"
                        class="form-control"
                        name="phone_number"
                        v-model="love.phone_number"
                        @input="onlyDigit($event)"
                        placeholder="09xxxxxxxxx"
                        required
                      />
                    </label>

                    <!-- <label
                      class="form-group"
                      v-if="actionBtnMethod == 'mpitesan'"
                    >
                      <span for class="mm-font __mm">Promotion Code</span>
                      <input
                        type="text"
                        class="form-control"
                        name="promocode"
                        v-model="promocode"
                      />
                    </label> -->
                    <p>
                      <input
                        @click.prevent="paymentProvider()"
                        :disabled="disabled"
                        type="submit"
                        class="woocommerce-Button button mm-font __mm"
                        name="save_account_details"
                        value="အတည်ပြုမည်"
                      />
                    </p>
                  </form>
                  <form
                    id="child-form"
                    role="form"
                    style="display: block"
                    v-if="getCurrentCategory.id === 11"
                  >
                    <!-- <h3
                      class="mm-font __mm"
                      align="center"
                    >{{ getCurrentCategory.title }}</h3> -->
                    <p class="mm-font __mm" align="center">
                      အောက်ပါပေးထားသော Form အချက်အလက်များကို ဖြည့်ပေးပါ
                    </p>
                    <label class="form-group">
                      <span class="mm-font __mm">မွေးနေ့(30.11.2004)</span>
                      <!-- <input
                        type="date"
                        class="form-control"
                        name="birth_date"
                        v-model="child.birth_date"
                        required
                      /> -->
                      <md-datepicker
                        value-format="YYYY-MM-DD"
                        class="input-container"
                        v-model="child.birth_date"
                      >
                        <!-- <label>Select date</label> -->
                      </md-datepicker>
                    </label>
                    <label class="form-group">
                      <span class="mm-font __mm">မွေးချိန်</span>
                      <!-- <input
                        type="text"
                        class="form-control"
                        name="birth_time"
                        v-model="child.birth_time"
                        required
                      /> -->
                      <div
                        style="
                          display: grid;
                          grid-template-columns: 1fr 2fr;
                          gap: 10px;
                        "
                      >
                        <select
                          class="mm-font __mm"
                          v-model="birth_am_pm"
                          required
                        >
                          <option value="" hidden>နံနက်/ည</option>
                          <option value="နံနက်">နံနက်ပိုင်း</option>
                          <option value="ည">ညနေပိုင်း</option>
                        </select>
                        <select
                          class="mm-font __mm"
                          v-model="birth_clock"
                          required
                        >
                          <option value="" hidden>အချိန်</option>
                          <option
                            v-for="t in [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]"
                            :key="t"
                            :value="t + 'နာရီ'"
                          >
                            {{ t }} နာရီ
                          </option>
                        </select>
                      </div>
                    </label>
                    <label class="form-group">
                      <span class="mm-font __mm">ဖခင်အမည်</span>
                      <input
                        type="text"
                        class="form-control"
                        name="father_name"
                        v-model="child.father_name"
                        required
                      />
                    </label>
                    <label class="form-group">
                      <span class="mm-font __mm">မိခင်အမည်</span>
                      <input
                        type="text"
                        class="form-control"
                        name="mother_name"
                        v-model="child.mother_name"
                        required
                      />
                    </label>
                    <!-- <label class="form-group">
                      <span for class="mm-font __mm">မွေးရပ်</span>
                      <input
                        type="text"
                        class="form-control"
                        name="birth_place"
                        v-model="child.birth_place"
                        required
                      />
                    </label> -->
                    <label class="form-group">
                      <span for class="mm-font __mm">မွေးရပ်</span>
                      <div
                        style="
                          display: grid;
                          grid-template-columns: 1fr 1fr;
                          gap: 10px;
                        "
                      >
                        <select
                          v-model="child.birth_place_division"
                          required
                          class="mm-font __mm"
                          @change="handleDivisionChange($event, '2')"
                        >
                          <option value="" selected hidden>
                            ပြည်နယ်/တိုင်း
                          </option>
                          <option
                            v-for="d in divisions2"
                            :data-nrc="d.nrc_id"
                            :value="d.name"
                            :key="d.name"
                            class="__mm"
                          >
                            {{ d.name }}
                          </option>
                        </select>
                        <select
                          v-model="child.birth_place_township"
                          required
                          class="mm-font __mm"
                        >
                          <option value="" selected hidden>မြို့နယ်</option>
                          <option
                            value=""
                            disabled
                            v-if="townships2.length == 0"
                          >
                            တိုင်း/ ပြည်နယ် ကိုအရင်ရွေးချယ်ပါ
                          </option>
                          <option
                            v-for="t in townships2"
                            :value="t.name"
                            :key="t.name"
                            class="__mm"
                          >
                            {{ t.name }}
                          </option>
                        </select>
                      </div>
                      <input
                        type="text"
                        class="mm-font __mm form-control"
                        placeholder="ရပ်ကွက်/လမ်း အသေးစိတ်"
                        v-model="child.birth_place"
                        required
                      />
                    </label>
                    <!-- <p class="mm-font __mm">
                      မှတ်ချက်။ ။ မိမိပေးလိုသော နေ့နံများကိုလည်းပြောပြပေးပါရန်။
                    </p> -->
                    <label class="form-group">
                      <span for class="mm-font __mm">နေ့နံ</span>
                      <select
                        class="mm-font __mm"
                        name="nyih_nan"
                        v-model="child.nyih_nan"
                        required
                      >
                        <option value="တနင်္ဂနွေ" class="__mm">
                          တနင်္ဂနွေ
                        </option>
                        <option value="တနင်္လာ" class="__mm">တနင်္လာ</option>
                        <option value="အင်္ဂါ" class="__mm">အင်္ဂါ</option>
                        <option value="ဗုဒ္ဓဟူး" class="__mm">ဗုဒ္ဓဟူး</option>
                        <option value="ရာဟု" class="__mm">ရာဟု</option>

                        <option value="ကြာသပတေး" class="__mm">ကြာသပတေး</option>
                        <option value="သောကြာ" class="__mm">သောကြာ</option>
                        <option value="စနေ" class="__mm">စနေ</option>
                      </select>
                    </label>

                    <input
                      type="radio"
                      name="gender"
                      v-model="child.gender"
                      value="Male"
                      required
                    />
                    <span for class="mm-font __mm">ယောကျ်ားလေး</span>
                    <input
                      type="radio"
                      name="gender"
                      v-model="child.gender"
                      value="Female"
                      required
                    />
                    <span for class="mm-font __mm">မိန်းကလေး</span>
                    <br />
                    <br />

                    <label class="form-group">
                      <span for class="mm-font __mm"
                        >ဆက်သွယ်ရမည့်ဖုန်းနံပါတ်</span
                      >
                      <input
                        type="text"
                        class="form-control"
                        name="phone_number"
                        v-model="child.phone_number"
                        @input="onlyDigit($event)"
                        placeholder="09xxxxxxxxx"
                        required
                      />
                    </label>
                    <!-- <label class="form-group">
                      <span for class="mm-font __mm"
                        >နေရပ်လိပ်စာ (မြို့နယ်)</span
                      >
                      <input
                        type="text"
                        class="form-control"
                        name="address"
                        v-model="child.address"
                        required
                      />
                    </label> -->
                    <label class="form-group">
                      <span for class="mm-font __mm">နေရပ်လိပ်စာ</span>
                      <div
                        style="
                          display: grid;
                          grid-template-columns: 1fr 1fr;
                          gap: 10px;
                        "
                      >
                        <select
                          v-model="child.address_division"
                          required
                          class="mm-font __mm"
                          @change="handleDivisionChange($event, '1')"
                        >
                          <option value="" selected hidden>
                            ပြည်နယ်/တိုင်း
                          </option>
                          <option
                            v-for="d in divisions1"
                            :data-nrc="d.nrc_id"
                            :value="d.name"
                            :key="d.name"
                            class="__mm"
                          >
                            {{ d.name }}
                          </option>
                        </select>
                        <select
                          v-model="child.address_township"
                          required
                          class="mm-font __mm"
                        >
                          <option value="" selected hidden>မြို့နယ်</option>
                          <option
                            value=""
                            disabled
                            v-if="townships1.length == 0"
                          >
                            တိုင်း/ ပြည်နယ် ကိုအရင်ရွေးချယ်ပါ
                          </option>
                          <option
                            v-for="t in townships1"
                            :value="t.name"
                            :key="t.name"
                            class="__mm"
                          >
                            {{ t.name }}
                          </option>
                        </select>
                      </div>
                      <input
                        type="text"
                        class="mm-font __mm form-control"
                        placeholder="ရပ်ကွက်/လမ်း အသေးစိတ်"
                        v-model="child.address"
                        required
                      />
                    </label>
                    <!-- <label
                      class="form-group"
                      v-if="actionBtnMethod == 'mpitesan'"
                    >
                      <span for class="mm-font __mm">Promotion Code</span>
                      <input
                        type="text"
                        class="form-control"
                        name="promocode"
                        v-model="promocode"
                      />
                    </label> -->
                    <p>
                      <input
                        @click.prevent="paymentProvider()"
                        :disabled="disabled"
                        type="submit"
                        class="woocommerce-Button button mm-font __mm"
                        name="save_account_details"
                        value="အတည်ပြုမည်"
                      />
                    </p>
                  </form>
                  <form
                    id="business-form"
                    role="form"
                    v-if="getCurrentCategory.id === 267"
                  >
                    <!-- <h3
                      class="mm-font __mm"
                      align="center"
                    >{{ getCurrentCategory.title }}</h3> -->
                    <p class="mm-font __mm" align="center">
                      အောက်ပါပေးထားသော Form အချက်အလက်များကို ဖြည့်ပေးပါ
                    </p>
                    <label class="form-group">
                      <span class="mm-font __mm">လုပ်ငန်းရှင်အမည်</span>
                      <input
                        type="text"
                        class="form-control"
                        name="owner_name"
                        v-model="business.owner_name"
                        required
                      />
                    </label>
                    <label class="form-group">
                      <span class="mm-font __mm">မွေးနေ့ </span>
                      <!-- <input
                        type="date"
                        class="form-control"
                        name="birth_date"
                        v-model="business.birth_date"
                        required
                      /> -->
                      <md-datepicker
                        value-format="YYYY-MM-DD"
                        class="input-container"
                        v-model="business.birth_date"
                      >
                        <!-- <label>Select date</label> -->
                      </md-datepicker>
                    </label>
                    <label class="form-group">
                      <span class="mm-font __mm">လုပ်ကိုင်မည့်လုပ်ငန်း</span>
                      <!-- <input
                        type="text"
                        class="form-control"
                        name="business_type"
                        v-model="business.business_type"
                        required
                      /> -->
                      <select
                        class="mm-font __mm"
                        name="business_type"
                        v-model="business.business_type"
                        required
                      >
                        <option value="" selected hidden>
                          လုပ်ငန်းအမျိုးအစား
                        </option>
                        <option value="ကြော်ငြာ ၊မီဒီယာနှင့်ဆက်သွယ်ရေးလုပ်ငန်း">
                          ကြော်ငြာ ၊မီဒီယာနှင့်ဆက်သွယ်ရေးလုပ်ငန်း
                        </option>
                        <option
                          value="စိုက်ပျိုးရေးလုပ်ငန်း၊ငါးလုပ်ငန်းနှင့်သစ်တောလုပ်ငန်း"
                        >
                          စိုက်ပျိုးရေးလုပ်ငန်း၊ငါးလုပ်ငန်းနှင့်သစ်တောလုပ်ငန်း
                        </option>
                        <option value="မော်တော်ယာဉ်နှင့်လေကြောင်းလုပ်ငန်း">
                          မော်တော်ယာဉ်နှင့်လေကြောင်းလုပ်ငန်း
                        </option>
                        <option value="ဘဏ် ၊ ငွေကြေး နှင့်အာမခံလုပ်ငန်း">
                          ဘဏ် ၊ ငွေကြေး နှင့်အာမခံလုပ်ငန်း
                        </option>
                        <option value="ဆောက်လုပ်ရေးလုပ်ငန်း">
                          ဆောက်လုပ်ရေးလုပ်ငန်း
                        </option>
                        <option value="ပညာရေးလုပ်ငန်း">ပညာရေးလုပ်ငန်း</option>
                        <option
                          value="ဖျော်ဖြေရေး၊ပွဲအခမ်းအနားများနှင့်အားကစားလုပ်ငန်း"
                        >
                          ဖျော်ဖြေရေး၊ပွဲအခမ်းအနားများနှင့်အားကစားလုပ်ငန်း
                        </option>
                        <option value="ကျန်းမာရေးဝန်ဆောင်မှုလုပ်ငန်း">
                          ကျန်းမာရေးဝန်ဆောင်မှုလုပ်ငန်း
                        </option>
                        <option value="ဟိုတယ်နှင့်ဧည့်ဝန်ဆောင်မှုလုပ်ငန်း">
                          ဟိုတယ်နှင့်ဧည့်ဝန်ဆောင်မှုလုပ်ငန်း
                        </option>
                        <option value="နည်းပညာနှင့်ဆက်သွယ်ရေးလုပ်ငန်း">
                          နည်းပညာနှင့်ဆက်သွယ်ရေးလုပ်ငန်း
                        </option>
                        <option value="ကုန်ထုတ်လုပ်မှု နှင့်သိုလှောင်လုပ်ငန်း">
                          ကုန်ထုတ်လုပ်မှု နှင့်သိုလှောင်လုပ်ငန်း
                        </option>
                        <option
                          value="စွမ်းအင် နှင့် သတ္တုတူးဖော်ထုတ်လုပ်ရေးလုပ်ငန်း"
                        >
                          စွမ်းအင် နှင့် သတ္တုတူးဖော်ထုတ်လုပ်ရေးလုပ်ငန်း
                        </option>
                        <option
                          value="အစိုးရမဟုတ်သောလူမှုအကျိုးပြုအဖွဲ့အစည်းများ"
                        >
                          အစိုးရမဟုတ်သောလူမှုအကျိုးပြုအဖွဲ့အစည်းများ
                        </option>
                        <option
                          value="အိမ်ခြံမြေနှင့် အထွေအထွေအကျိုးတော်ဆောင်လုပ်ငန်း"
                        >
                          အိမ်ခြံမြေနှင့် အထွေအထွေအကျိုးတော်ဆောင်လုပ်ငန်း
                        </option>
                        <option value="ဝန်ထမ်းရွေးချယ်ခန့်ထားရေးလုပ်ငန်း">
                          ဝန်ထမ်းရွေးချယ်ခန့်ထားရေးလုပ်ငန်း
                        </option>
                        <option
                          value="လက်မှုအနုပညာ ၊ အလှကုန် နှင့် ဖက်ရှင် လုပ်ငန်း"
                        >
                          လက်မှုအနုပညာ ၊ အလှကုန် နှင့် ဖက်ရှင် လုပ်ငန်း
                        </option>
                        <option
                          value="အရောင်းကိုယ်စားလှယ်၊လက်လီ၊လူသုံးကုန်နှင့်စားသောက်ကုန်လုပ်ငန်း"
                        >
                          အရောင်းကိုယ်စားလှယ်၊လက်လီ၊လူသုံးကုန်နှင့်စားသောက်ကုန်လုပ်ငန်း
                        </option>
                        <option
                          value="ကူးသန်းရောင်းဝယ်ရေး နှင့် သယ်ယူပိုဆောင်ရေးလုပ်ငန်း"
                        >
                          ကူးသန်းရောင်းဝယ်ရေး နှင့် သယ်ယူပိုဆောင်ရေးလုပ်ငန်း
                        </option>
                        <option value="ခရီးသွားဝန်ဆောင်မှုလုပ်ငန်း">
                          ခရီးသွားဝန်ဆောင်မှုလုပ်ငန်း
                        </option>
                        <option value="အခြားလုပ်ငန်းများ">
                          အခြားလုပ်ငန်းများ
                        </option>
                      </select>
                      <input
                        type="text"
                        class="form-control mm-font"
                        name="business_detail"
                        v-model="business.business_detail"
                        placeholder="လုပ်ငန်းအသေးစိတ်"
                      />
                    </label>
                    <label class="form-group">
                      <span for class="mm-font __mm">နေရပ်လိပ်စာ</span>
                      <div
                        style="
                          display: grid;
                          grid-template-columns: 1fr 1fr;
                          gap: 10px;
                        "
                      >
                        <select
                          v-model="business.address_division"
                          required
                          class="mm-font __mm"
                          @change="handleDivisionChange($event, '1')"
                        >
                          <option value="" selected hidden>
                            ပြည်နယ်/တိုင်း
                          </option>
                          <option
                            v-for="d in divisions1"
                            :data-nrc="d.nrc_id"
                            :value="d.name"
                            :key="d.name"
                            class="__mm"
                          >
                            {{ d.name }}
                          </option>
                        </select>
                        <select
                          v-model="business.address_township"
                          required
                          class="mm-font __mm"
                        >
                          <option value="" selected hidden>မြို့နယ်</option>
                          <option
                            value=""
                            disabled
                            v-if="townships1.length == 0"
                          >
                            တိုင်း/ ပြည်နယ် ကိုအရင်ရွေးချယ်ပါ
                          </option>
                          <option
                            v-for="t in townships1"
                            :value="t.name"
                            :key="t.name"
                            class="__mm"
                          >
                            {{ t.name }}
                          </option>
                        </select>
                      </div>
                      <input
                        type="text"
                        class="mm-font __mm form-control"
                        placeholder="ရွာ/ လမ်း အသေးစိတ်"
                        v-model="business.address"
                        required
                      />
                    </label>
                    <!-- <label class="form-group">
                      <span class="mm-font __mm">လုပ်ငန်းလိပ်စာ</span>
                      <input
                        type="text"
                        class="form-control"
                        name="address"
                        v-model="business.address"
                        required
                      />
                    </label> -->
                    <label class="form-group">
                      <span for class="mm-font __mm"
                        >ဆက်သွယ်ရမည့်ဖုန်းနံပါတ်</span
                      >
                      <input
                        type="text"
                        class="form-control"
                        name="phone_number"
                        v-model="business.phone_number"
                        @input="onlyDigit($event)"
                        placeholder="09xxxxxxxxx"
                        required
                      />
                    </label>
                    <label class="form-group">
                      <span for class="mm-font __mm">နေ့သား(လုပ်ငန်းရှင်)</span>
                      <select
                        class="mm-font __mm"
                        name="nyih_tha"
                        v-model="business.nyih_tha"
                        required
                      >
                        <option value="တနင်္ဂနွေ" class="__mm">
                          တနင်္ဂနွေ
                        </option>
                        <option value="တနင်္လာ" class="__mm">တနင်္လာ</option>
                        <option value="အင်္ဂါ" class="__mm">အင်္ဂါ</option>
                        <option value="ဗုဒ္ဓဟူး" class="__mm">ဗုဒ္ဓဟူး</option>
                        <option value="ရာဟု" class="__mm">ရာဟု</option>

                        <option value="ကြာသပတေး" class="__mm">ကြာသပတေး</option>
                        <option value="သောကြာ" class="__mm">သောကြာ</option>
                        <option value="စနေ" class="__mm">စနေ</option>
                      </select>
                    </label>
                    <!-- <label
                      class="form-group"
                      v-if="actionBtnMethod == 'mpitesan'"
                    >
                      <span for class="mm-font __mm">Promotion Code</span>
                      <input
                        type="text"
                        class="form-control"
                        name="promocode"
                        v-model="promocode"
                      />
                    </label> -->
                    <p>
                      <input
                        @click.prevent="paymentProvider()"
                        :disabled="disabled"
                        type="submit"
                        class="woocommerce-Button button mm-font __mm"
                        name="save_account_details"
                        value="အတည်ပြုမည်"
                      />
                    </p>
                  </form>
                  <form
                    id="child-form"
                    role="form"
                    style="display: block"
                    v-if="getCurrentCategory.id === 10"
                  >
                    <!-- <h3
                      class="mm-font __mm"
                      align="center"
                    >{{ getCurrentCategory.title }}</h3> -->
                    <p class="mm-font __mm" align="center">
                      အောက်ပါပေးထားသော Form အချက်အလက်များကို ဖြည့်ပေးပါ
                    </p>
                    <label class="form-group">
                      <span class="mm-font __mm">မွေးနေ့</span>
                      <!-- <input
                        type="date"
                        class="form-control"
                        name="birth_date"
                        v-model="oneyear.birth_date"
                        required
                      /> -->

                      <md-datepicker
                        v-model="oneyear.birth_date"
                        value-format="YYYY-MM-DD"
                        class="input-container"
                        required
                      >
                        <!-- <label>Select date</label> -->
                      </md-datepicker>
                    </label>

                    <label class="form-group">
                      <span class="mm-font __mm">မွေးချိန်</span>
                      <!-- <input
                        type="text"
                        class="form-control"
                        name="birth_time"
                        v-model="oneyear.birth_time"
                        required
                      /> -->
                      <div
                        style="
                          display: grid;
                          grid-template-columns: 1fr 2fr;
                          gap: 10px;
                        "
                      >
                        <select
                          class="mm-font __mm"
                          v-model="birth_am_pm"
                          required
                        >
                          <option value="" hidden>နံနက်/ည</option>
                          <option value="နံနက်">နံနက်ပိုင်း</option>
                          <option value="ည">ညနေပိုင်း</option>
                        </select>
                        <select
                          class="mm-font __mm"
                          v-model="birth_clock"
                          required
                        >
                          <option value="" hidden>အချိန်</option>
                          <option
                            v-for="t in [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]"
                            :key="t"
                            :value="t + ' နာရီ'"
                          >
                            {{ t }} နာရီ
                          </option>
                        </select>
                      </div>
                    </label>
                    <label class="form-group">
                      <span class="mm-font __mm">အမည်</span>
                      <input
                        type="text"
                        class="form-control"
                        name="name"
                        v-model="oneyear.name"
                        required
                      />
                    </label>

                    <label class="form-group">
                      <span for class="mm-font __mm">မွေးရပ်</span>
                      <div
                        style="
                          display: grid;
                          grid-template-columns: 1fr 1fr;
                          gap: 10px;
                        "
                      >
                        <select
                          v-model="oneyear.birth_place_division"
                          required
                          class="mm-font __mm"
                          @change="handleDivisionChange($event, '2')"
                        >
                          <option value="" selected hidden>
                            ပြည်နယ်/တိုင်း
                          </option>
                          <option
                            v-for="d in divisions2"
                            :data-nrc="d.nrc_id"
                            :value="d.name"
                            :key="d.name"
                            class="__mm"
                          >
                            {{ d.name }}
                          </option>
                        </select>
                        <select
                          v-model="oneyear.birth_place_township"
                          required
                          class="mm-font __mm"
                        >
                          <option value="" selected hidden>မြို့နယ်</option>
                          <option
                            value=""
                            disabled
                            v-if="townships2.length == 0"
                          >
                            တိုင်း/ ပြည်နယ် ကိုအရင်ရွေးချယ်ပါ
                          </option>
                          <option
                            v-for="t in townships2"
                            :value="t.name"
                            :key="t.name"
                            class="__mm"
                          >
                            {{ t.name }}
                          </option>
                        </select>
                      </div>
                      <input
                        type="text"
                        class="mm-font __mm form-control"
                        placeholder="ရပ်ကွက်/လမ်း အသေးစိတ်"
                        v-model="oneyear.birth_place"
                        required
                      />
                    </label>

                    <!-- <label class="form-group">
                      <span for class="mm-font __mm">မွေးရပ်</span>
                      <input
                        type="text"
                        class="form-control"
                        name="birth_place"
                        v-model="oneyear.birth_place"
                        required
                      />
                    </label> -->
                    <label class="form-group">
                      <span for class="mm-font __mm">အလုပ်အကိုင်</span>
                      <!-- <input
                        type="text"
                        class="form-control"
                        name="career"
                        v-model="oneyear.career"
                        required
                      /> -->
                      <div
                        style="
                          display: grid;
                          grid-template-rows: 1fr 1fr;
                          gap: 5px;
                        "
                      >
                        <select
                          name="job"
                          class="mm-font __mm"
                          v-model="oneyear.career"
                          required
                        >
                          <option value="" selected hidden>
                            အလုပ်အကိုင် အမျိုးအစား
                          </option>
                          <option value="အစိုးရဝန်ထမ်း">အစိုးရဝန်ထမ်း</option>
                          <option value="ကုမ္ပဏီဝန်ထမ်း">ကုမ္ပဏီဝန်ထမ်း</option>
                          <option value="ကိုယ်ပိုင်စီးပွားရေး">
                            ကိုယ်ပိုင်စီးပွားရေး
                          </option>
                          <option value="ကျောင်းသား/သူ">ကျောင်းသား/သူ</option>
                          <option value="အခြား">အခြား</option>
                        </select>
                        <input
                          type="text"
                          class="form-control"
                          name="job_detail"
                          v-model="oneyear.career_detail"
                          placeholder="အလုပ်ကိုင်အသေးစိတ်"
                        />
                      </div>
                    </label>
                    <label class="form-group">
                      <span for class="mm-font __mm">နေရပ်လိပ်စာ</span>
                      <div
                        style="
                          display: grid;
                          grid-template-columns: 1fr 1fr;
                          gap: 10px;
                        "
                      >
                        <select
                          v-model="oneyear.address_division"
                          required
                          class="mm-font __mm"
                          @change="handleDivisionChange($event, '1')"
                        >
                          <option value="" selected hidden>
                            ပြည်နယ်/တိုင်း
                          </option>
                          <option
                            v-for="d in divisions1"
                            :data-nrc="d.nrc_id"
                            :value="d.name"
                            :key="d.name"
                            class="__mm"
                          >
                            {{ d.name }}
                          </option>
                        </select>
                        <select
                          v-model="oneyear.address_township"
                          required
                          class="mm-font __mm"
                        >
                          <option value="" selected hidden>မြို့နယ်</option>
                          <option
                            value=""
                            disabled
                            v-if="townships1.length == 0"
                          >
                            တိုင်း/ ပြည်နယ် ကိုအရင်ရွေးချယ်ပါ
                          </option>
                          <option
                            v-for="t in townships1"
                            :value="t.name"
                            :key="t.name"
                            class="__mm"
                          >
                            {{ t.name }}
                          </option>
                        </select>
                      </div>
                      <input
                        type="text"
                        class="mm-font __mm form-control"
                        placeholder="ရပ်ကွက်/လမ်း အသေးစိတ်"
                        v-model="oneyear.address"
                        required
                      />
                    </label>
                    <input
                      type="radio"
                      name="marital_status"
                      value="Yes"
                      v-model="oneyear.marital_status"
                      required
                    />
                    <span for class="mm-font __mm">အိမ်ထောင်ရှိ</span>
                    <input
                      type="radio"
                      name="marital_status"
                      value="No"
                      v-model="oneyear.marital_status"
                      required
                    />
                    <span for class="mm-font __mm">အိမ်ထောင်မရှိ</span>
                    <br />
                    <br />

                    <label class="form-group">
                      <span for class="mm-font __mm">နေ့နံ</span>
                      <select
                        name="g_birth_day"
                        v-model="oneyear.nyih_nan"
                        class="mm-font __mm"
                        required
                      >
                        <option value="တနင်္ဂနွေ" class="__mm">
                          တနင်္ဂနွေ
                        </option>
                        <option value="တနင်္လာ" class="__mm">တနင်္လာ</option>
                        <option value="အင်္ဂါ" class="__mm">အင်္ဂါ</option>
                        <option value="ဗုဒ္ဓဟူး" class="__mm">ဗုဒ္ဓဟူး</option>
                        <option value="ရာဟု" class="__mm">ရာဟု</option>

                        <option value="ကြာသပတေး" class="__mm">ကြာသပတေး</option>
                        <option value="သောကြာ" class="__mm">သောကြာ</option>
                        <option value="စနေ" class="__mm">စနေ</option>
                      </select>
                    </label>
                    <label class="form-group">
                      <span for class="mm-font __mm"
                        >အဓိကသိချင်သောအကြောင်းအရာ</span
                      >
                      <input
                        type="text"
                        class="form-control"
                        name="about"
                        v-model="oneyear.about"
                        maxlength="1000"
                        required
                      />
                    </label>
                    <label class="form-group">
                      <span for class="mm-font __mm"
                        >ဆက်သွယ်ရမည့်ဖုန်းနံပါတ်</span
                      >
                      <input
                        type="text"
                        class="form-control"
                        name="phone_number"
                        v-model="oneyear.phone_number"
                        @input="onlyDigit($event)"
                        placeholder="09xxxxxxxxx"
                        required
                      />
                    </label>
                    <!-- <label
                      class="form-group"
                      v-if="actionBtnMethod == 'mpitesan'"
                    >
                      <span for class="mm-font __mm">Promotion Code</span>
                      <input
                        type="text"
                        class="form-control"
                        name="promocode"
                        v-model="promocode"
                      />
                    </label> -->
                    <p>
                      <input
                        @click.prevent="paymentProvider()"
                        :disabled="disabled"
                        type="submit"
                        class="woocommerce-Button button mm-font __mm"
                        name="save_account_details"
                        value="အတည်ပြုမည်"
                        style=""
                      />
                    </p>
                  </form>
                </div>
              </div>
            </div>
            <!-- .entry-content -->
          </article>
        </main>
      </div>
    </div>
  </section>
</template>
<style >
input {
  background-color: red;
}

.input-container {
  display: flex;
  border: 1px solid #000;
  background-color: #f2eff7;
  color: #3e1e68;
  border-radius: 25px;
  padding: 8px 0px 0px 10px;
}
.md-field.md-theme-default:after {
  background-color: rgba(0, 0, 0, 0);
}
.md-button.md-theme-default {
  margin: 0px 16px 0px 0px;
}
</style>

<script>
import { mapGetters } from "vuex";
import { Horo, HTTP } from "@core/lib/http-common";
import { getFormData, checkFormModel } from "@core/lib/form-data";
import { makePayment, onePay, citizenPay } from "../js/payment";
import { getDivisions, getTownships } from "../js/addressInfo";
import moment from "moment";
// import Datepicker from 'vue-material-datepicker';

export default {
  components: {
    // datepicker : Datepicker
  },
  name: `Special`,
  data() {
    return {
      divisions1: [],
      divisions2: [],
      townships1: [],
      townships2: [],
      birth_am_pm: "",
      birth_clock: "",
      actionBtnMethod: "",
      disabled: false,
      res_status: 0,
      message: "",
      onepayPhoneNumber: "",
      // promocode: "",
      datePickerOptions: {
        format: "DD/MM/YYYY",
        useCurrent: false,
      },
      love: {
        g_name: "",
        g_birth_day: "",
        g_birth_date: moment(new Date("01/01/1970")).format("YYYY-MM-DD"),
        g_address: "",
        g_address_division: "",
        g_address_township: "",
        b_name: "",
        b_birth_day: "",
        b_birth_date: moment(new Date("01/01/1970")).format("YYYY-MM-DD"),
        b_address: "",
        b_address_division: "",
        b_address_township: "",
        phone_number: "",
        order_id: "0",
        user_id: "0",
        price: "0",
      },
      child: {
        birth_date: moment(new Date()).format("YYYY-MM-DD"),
        birth_time: "",
        father_name: "",
        mother_name: "",
        birth_place: "",
        birth_place_division: "",
        birth_place_township: "",
        nyih_nan: "",
        order_id: "0",
        address: "",
        address_division: "",
        address_township: "",
        phone_number: "",
        gender: "",
        user_id: "0",
        price: "0",
      },
      oneyear: {
        birth_date: moment(new Date("01/01/1930")).format("YYYY-MM-DD"),
        birth_time: "",
        birth_place: "",
        birth_place_division: "",
        birth_place_township: "",
        nyih_nan: "",
        career: "",
        career_detail: "",
        marital_status: "",
        phone_number: "",
        order_id: "0",
        user_id: "0",
        about: "",
        address: "",
        address_division: "",
        address_township: "",
        price: "0",
      },
      business: {
        owner_name: "",
        user_id: "0",
        birth_date: moment(new Date()).format("YYYY-MM-DD"),
        business_type: "",
        business_detail: "",
        phone_number: "",
        address: "",
        address_division: "",
        address_township: "",
        nyih_tha: "",
        order_id: "0",
        price: "0",
      },
    };
  },
  computed: {
    ...mapGetters({
      getCurrentCategory: "categoryStore/getCurrentCategory",
      getUserToken: "userStore/getUserToken",
      getResponse: "userStore/getResponse",
    }),
  },

  async created() {
    this.divisions1 = await getDivisions();
    this.divisions2 = { ...this.divisions1 };
    this.actionBtnMethod = this.$store.state.paymentName;
    this.onepayPhoneNumber =
      this.$store.state.paymentName == "onepay"
        ? this.$store.state.onepayPhoneNumber
        : "";

    this.$store.dispatch(
      "categoryStore/singleCategory",
      this.$route.params.categoryId
    );
  },

  mounted() {},

  watch: {
    "oneyear.birth_date"(updatedDate) {
      let formattedDate = moment(updatedDate).format("YYYY-MM-DD");

      return formattedDate;
    },
    birth_am_pm: function (value) {
      let val = value + " " + this.birth_clock;
      switch (this.getCurrentCategory.id) {
        case 10:
          this.oneyear.birth_time = val;
          break;
        case 11:
          this.child.birth_time = val;
          break;
      }
    },
    birth_clock: function (value) {
      let val = this.birth_am_pm + " " + value;
      switch (this.getCurrentCategory.id) {
        case 10:
          this.oneyear.birth_time = val;
          break;
        case 11:
          this.child.birth_time = val;
          break;
      }
    },
  },

  methods: {
    async handleDivisionChange($event, division_id) {
      let nrc_id =
        $event.target.options[$event.target.selectedIndex].dataset.nrc;
      let townships = await getTownships(nrc_id);
      if (division_id == "1") {
        this.townships1 = townships;
      } else if (division_id == "2") {
        this.townships2 = townships;
      }
    },

    onlyDigit($event) {
      let key = $event.keyCode || $event.charCode;
      if ($event.target.value.length > 11 && key != 8) {
        $event.target.value = $event.target.value.substr(
          0,
          $event.target.value.length - 1
        );
      }

      $event.target.value = $event.target.value.replace(/[^\d]/gi, "");
    },

    paymentProvider() {
      let getPaymentName = this.actionBtnMethod;
      switch (getPaymentName) {
        case "mpitesan":
          this.makePayment();
          break;
        case "onepay":
          this.onePayProvider();
          break;
        case "citizenpay":
          this.citizenPayProvicer();
          break;
        case "bppay":
          this.bpPay();
          break;
        default:
          this.precreate();
          break;
      }
    },

    async citizenPayProvicer() {
      if (this.child.birth_time == "") {
        this.child.birth_time = " ";
      }
      if (this.oneyear.birth_time == "") {
        this.oneyear.birth_time = " ";
      }

      if (this.getCurrentCategory.id === 275 && checkFormModel(this.love)) {
        this.disabled = true;
        this.love.price = this.getCurrentCategory.price;
        let formdata = getFormData(this.love);
        formdata.append("user_phone", this.love.phone_number);
        let payment_method =
          this.$store.state.paymentName == " "
            ? "kbz"
            : this.$store.state.paymentName;
        formdata.append("payment_method", payment_method);
        let cdata = {
          category_id: this.getCurrentCategory.id,
          amount: this.getCurrentCategory.price,
          category_name: "love",
          // phone_number:
          //   this.onepayPhoneNumber == ""
          //     ? this.direct.phone_number
          //     : this.onepayPhoneNumber,
        };
        await citizenPay([
          "1875/love/horoscope",
          formdata,
          cdata,
          this.$store.state.backendUrl,
          this.getCurrentCategory.price,
        ])
          .then((data) => {
            // console.log(data);
            if (data.status == 200) {
              window.location.href = data.paymentGatway;
              // console.log(data);
              // this.$store.state.order_id = data.order_id;
              // this.$store.state.amount = data.amount;
              // this.$router.push({
              //   name: "paymentPortal",
              // });
            } else {
              this.$swal("Oops", "Something went wrong!", "error");
            }
          })
          .catch(() => {
            this.$swal("Oops", "Something went wrong!", "error");
          });
      } else if (
        this.getCurrentCategory.id === 11 &&
        checkFormModel(this.child)
      ) {
        this.disabled = true;
        this.child.price = this.getCurrentCategory.price;
        let formdata = getFormData(this.child);
        formdata.append("user_phone", this.child.phone_number);
        let payment_method =
          this.$store.state.paymentName == " "
            ? "kbz"
            : this.$store.state.paymentName;
        formdata.append("payment_method", payment_method);
        let cdata = {
          category_id: this.getCurrentCategory.id,
          amount: this.getCurrentCategory.price,
          category_name: "child",
          // phone_number:
          //   this.onepayPhoneNumber == ""
          //     ? this.direct.phone_number
          //     : this.onepayPhoneNumber,
        };
        await citizenPay([
          "1875/childNaming/horoscope",
          formdata,
          cdata,
          this.$store.state.backendUrl,
          this.getCurrentCategory.price,
        ])
          .then((data) => {
            // console.log(data);
            if (data.status == 200) {
              window.location.href = data.paymentGatway;
              // console.log(data);
              // this.$store.state.order_id = data.order_id;
              // this.$store.state.amount = data.amount;
              // this.$router.push({
              //   name: "paymentPortal",
              // });
            } else {
              this.$swal("Oops", "Something went wrong!", "error");
            }
          })
          .catch(() => {
            this.$swal("Oops", "Something went wrong!", "error");
          });
      } else if (
        this.getCurrentCategory.id === 10 &&
        checkFormModel(this.oneyear)
      ) {
        this.disabled = true;
        this.oneyear.price = this.getCurrentCategory.price;
        let formdata = getFormData(this.oneyear);
        formdata.append("user_phone", this.oneyear.phone_number);
        let payment_method =
          this.$store.state.paymentName == " "
            ? "kbz"
            : this.$store.state.paymentName;
        formdata.append("payment_method", payment_method);
        let cdata = {
          category_id: this.getCurrentCategory.id,
          amount: this.getCurrentCategory.price,
          category_name: "oneyear",
          // phone_number:
          //   this.onepayPhoneNumber == ""
          //     ? this.direct.phone_number
          //     : this.onepayPhoneNumber,
        };
        await citizenPay([
          "1875/oneyear/horoscope",
          formdata,
          cdata,
          this.$store.state.backendUrl,
          this.getCurrentCategory.price,
        ])
          .then((data) => {
            if (data.status == 200) {
              window.location.href = data.paymentGatway;
            } else {
              this.$swal("Oops", "Something went wrong!", "error");
            }
          })
          .catch(() => {
            this.$swal("Oops", "Something went wrong!", "error");
          });
      } else if (
        this.getCurrentCategory.id === 267 &&
        checkFormModel(this.business)
      ) {
        this.disabled = true;
        this.business.price = this.getCurrentCategory.price;
        let formdata = getFormData(this.business);
        formdata.append("user_phone", this.business.phone_number);
        let payment_method =
          this.$store.state.paymentName == " "
            ? "kbz"
            : this.$store.state.paymentName;
        formdata.append("payment_method", payment_method);
        let cdata = {
          category_id: this.getCurrentCategory.id,
          amount: this.getCurrentCategory.price,
          category_name: "business",
          // phone_number:
          //   this.onepayPhoneNumber == ""
          //     ? this.direct.phone_number
          //     : this.onepayPhoneNumber,
        };
        await citizenPay([
          "1875/businessNaming/horoscope",
          formdata,
          cdata,
          this.$store.state.backendUrl,
          this.getCurrentCategory.price,
        ])
          .then((data) => {
            // console.log(data);
            if (data.status == 200) {
              window.location.href = data.paymentGatway;
              // console.log(data);
              // this.$store.state.order_id = data.order_id;
              // this.$store.state.amount = data.amount;
              // this.$router.push({
              //   name: "paymentPortal",
              // });
            } else {
              this.$swal("Oops", "Something went wrong!", "error");
            }
          })
          .catch(() => {
            this.$swal("Oops", "Something went wrong!", "error");
          });
      } else {
        this.$swal("Oops", "You need to fill all fields", "error");
      }
    },

    async onePayProvider() {
      if (this.child.birth_time == "") {
        this.child.birth_time = " ";
      }
      if (this.oneyear.birth_time == "") {
        this.oneyear.birth_time = " ";
      }

      if (this.getCurrentCategory.id === 275 && checkFormModel(this.love)) {
        this.disabled = true;
        this.love.price = this.getCurrentCategory.price;
        let formdata = getFormData(this.love);
        formdata.append("user_phone", this.love.phone_number);
        let payment_method =
          this.$store.state.paymentName == " "
            ? "kbz"
            : this.$store.state.paymentName;
        formdata.append("payment_method", payment_method);
        let cdata = {
          category_id: this.getCurrentCategory.id,
          amount: this.getCurrentCategory.price,
          category_name: "love",
          phone_number:
            this.onepayPhoneNumber == ""
              ? this.direct.phone_number
              : this.onepayPhoneNumber,
        };
        await onePay([
          "1875/love/horoscope",
          formdata,
          cdata,
          this.$store.state.backendUrl,
          this.getCurrentCategory.price,
        ])
          .then((data) => {
            // console.log(data);
            if (data.status == 200) {
              // console.log(data);
              this.$store.state.order_id = data.order_id;
              this.$store.state.amount = data.amount;
              // this.$router.push({
              //   name: "paymentPortal",
              // });
            } else {
              this.$swal("Oops", "Something went wrong!", "error");
            }
          })
          .catch(() => {
            this.$swal("Oops", "Something went wrong!", "error");
          });
      } else if (
        this.getCurrentCategory.id === 11 &&
        checkFormModel(this.child)
      ) {
        this.disabled = true;
        this.child.price = this.getCurrentCategory.price;
        let formdata = getFormData(this.child);
        formdata.append("user_phone", this.child.phone_number);
        let payment_method =
          this.$store.state.paymentName == " "
            ? "kbz"
            : this.$store.state.paymentName;
        formdata.append("payment_method", payment_method);
        let cdata = {
          category_id: this.getCurrentCategory.id,
          amount: this.getCurrentCategory.price,
          category_name: "child",
          phone_number:
            this.onepayPhoneNumber == ""
              ? this.direct.phone_number
              : this.onepayPhoneNumber,
        };
        await onePay([
          "1875/childNaming/horoscope",
          formdata,
          cdata,
          this.$store.state.backendUrl,
          this.getCurrentCategory.price,
        ])
          .then((data) => {
            // console.log(data);
            if (data.status == 200) {
              // console.log(data);
              this.$store.state.order_id = data.order_id;
              this.$store.state.amount = data.amount;
              // this.$router.push({
              //   name: "paymentPortal",
              // });
            } else {
              this.$swal("Oops", "Something went wrong!", "error");
            }
          })
          .catch(() => {
            this.$swal("Oops", "Something went wrong!", "error");
          });
      } else if (
        this.getCurrentCategory.id === 10 &&
        checkFormModel(this.oneyear)
      ) {
        this.disabled = true;
        this.oneyear.price = this.getCurrentCategory.price;
        let formdata = getFormData(this.oneyear);
        formdata.append("user_phone", this.oneyear.phone_number);
        let payment_method =
          this.$store.state.paymentName == " "
            ? "kbz"
            : this.$store.state.paymentName;
        formdata.append("payment_method", payment_method);
        let cdata = {
          category_id: this.getCurrentCategory.id,
          amount: this.getCurrentCategory.price,
          category_name: "oneyear",
          phone_number:
            this.onepayPhoneNumber == ""
              ? this.direct.phone_number
              : this.onepayPhoneNumber,
        };
        await onePay([
          "1875/oneyear/horoscope",
          formdata,
          cdata,
          this.$store.state.backendUrl,
          this.getCurrentCategory.price,
        ])
          .then((data) => {
            // console.log(data);
            if (data.status == 200) {
              // console.log(data);
              this.$store.state.order_id = data.order_id;
              this.$store.state.amount = data.amount;
              // this.$router.push({
              //   name: "paymentPortal",
              // });
            } else {
              this.$swal("Oops", "Something went wrong!", "error");
            }
          })
          .catch(() => {
            this.$swal("Oops", "Something went wrong!", "error");
          });
      } else if (
        this.getCurrentCategory.id === 267 &&
        checkFormModel(this.business)
      ) {
        this.disabled = true;
        this.business.price = this.getCurrentCategory.price;
        let formdata = getFormData(this.business);
        formdata.append("user_phone", this.business.phone_number);
        let payment_method =
          this.$store.state.paymentName == " "
            ? "kbz"
            : this.$store.state.paymentName;
        formdata.append("payment_method", payment_method);
        let cdata = {
          category_id: this.getCurrentCategory.id,
          amount: this.getCurrentCategory.price,
          category_name: "business",
          phone_number:
            this.onepayPhoneNumber == ""
              ? this.direct.phone_number
              : this.onepayPhoneNumber,
        };
        await onePay([
          "1875/businessNaming/horoscope",
          formdata,
          cdata,
          this.$store.state.backendUrl,
          this.getCurrentCategory.price,
        ])
          .then((data) => {
            // console.log(data);
            if (data.status == 200) {
              // console.log(data);
              this.$store.state.order_id = data.order_id;
              this.$store.state.amount = data.amount;
              // this.$router.push({
              //   name: "paymentPortal",
              // });
            } else {
              this.$swal("Oops", "Something went wrong!", "error");
            }
          })
          .catch(() => {
            this.$swal("Oops", "Something went wrong!", "error");
          });
      } else {
        this.$swal("Oops", "You need to fill all fields", "error");
      }
    },

    async bpPay() {
      if (this.child.birth_time == "") {
        this.child.birth_time = " ";
      }
      if (this.oneyear.birth_time == "") {
        this.oneyear.birth_time = " ";
      }

      if (this.getCurrentCategory.id === 275 && checkFormModel(this.love)) {
        this.disabled = true;
        this.love.price = this.getCurrentCategory.price;
        let formdata = getFormData(this.love);
        formdata.append("user_phone", this.love.phone_number);
        let payment_method =
          this.$store.state.paymentName == " "
            ? "kbz"
            : this.$store.state.paymentName;
        formdata.append("payment_method", payment_method);
        let cdata = {
          category_id: this.getCurrentCategory.id,
          amount: this.getCurrentCategory.price,
          category_name: "love",
          payment_name: this.actionBtnMethod,
          phone_number: this.love.phone_number,
          // promocode: this.promocode,
        };
        await makePayment([
          "1875/love/horoscope",
          formdata,
          cdata,
          this.$store.state.backendUrl,
          this.getCurrentCategory.price,
        ])
          .then((data) => {
            // console.log(data);
            if (data.status == 200) {
              // console.log(data);
              this.$store.state.order_id = data.order_id;
              this.$store.state.amount = data.amount;
              if (!data.payment_status) {
                this.$router.push({
                  name: "paymentPortalV3",
                });
              } else {
                this.$router.push({
                  name: "PaymentSuccess",
                  params: { payment: "PWA BPPay" },
                });
              }
            } else {
              this.$swal("Oops", "Something went wrong!", "error");
            }
          })
          .catch(() => {
            this.$swal("Oops", "Something went wrong!", "error");
          });
      } else if (
        this.getCurrentCategory.id === 11 &&
        checkFormModel(this.child)
      ) {
        this.disabled = true;
        this.child.price = this.getCurrentCategory.price;
        let formdata = getFormData(this.child);
        formdata.append("user_phone", this.child.phone_number);
        let payment_method =
          this.$store.state.paymentName == " "
            ? "kbz"
            : this.$store.state.paymentName;
        formdata.append("payment_method", payment_method);
        let cdata = {
          category_id: this.getCurrentCategory.id,
          amount: this.getCurrentCategory.price,
          category_name: "child",
          // promocode: this.promocode,
          phone_number: this.child.phone_number,
          payment_name: this.actionBtnMethod,
        };
        await makePayment([
          "1875/childNaming/horoscope",
          formdata,
          cdata,
          this.$store.state.backendUrl,
          this.getCurrentCategory.price,
        ])
          .then((data) => {
            // console.log(data);
            if (data.status == 200) {
              // console.log(data);
              this.$store.state.order_id = data.order_id;
              this.$store.state.amount = data.amount;
              if (!data.payment_status) {
                this.$router.push({
                  name: "paymentPortalV3",
                });
              } else {
                this.$router.push({
                  name: "PaymentSuccess",
                  params: { payment: "PWA BPPay" },
                });
              }
            } else {
              this.$swal("Oops", "Something went wrong!", "error");
            }
          })
          .catch(() => {
            this.$swal("Oops", "Something went wrong!", "error");
          });
      } else if (
        this.getCurrentCategory.id === 10 &&
        checkFormModel(this.oneyear)
      ) {
        this.disabled = true;
        if (this.oneyear.phone_number == "09251569702") {
          this.oneyear.price = 1;
        } else {
          this.oneyear.price = this.getCurrentCategory.price;
        }
        let formdata = getFormData(this.oneyear);
        formdata.append("user_phone", this.oneyear.phone_number);
        let payment_method =
          this.$store.state.paymentName == " "
            ? "kbz"
            : this.$store.state.paymentName;
        formdata.append("payment_method", payment_method);
        let cdata = {
          category_id: this.getCurrentCategory.id,
          amount: this.getCurrentCategory.price,
          category_name: "oneyear",
          // promocode: this.promocode,
          phone_number: this.oneyear.phone_number,
          payment_name: this.actionBtnMethod,
        };
        await makePayment([
          "1875/oneyear/horoscope",
          formdata,
          cdata,
          this.$store.state.backendUrl,
          this.getCurrentCategory.price,
        ])
          .then((data) => {
            // console.log(data);
            if (data.status == 200) {
              // console.log(data);
              this.$store.state.order_id = data.order_id;
              this.$store.state.amount = data.amount;
              if (!data.payment_status) {
                this.$router.push({
                  name: "paymentPortalV3",
                });
              } else {
                this.$router.push({
                  name: "PaymentSuccess",
                  params: { payment: "PWA BPPay" },
                });
              }
            } else {
              this.$swal("Oops", "Something went wrong!", "error");
            }
          })
          .catch(() => {
            this.$swal("Oops", "Something went wrong!", "error");
          });
      } else if (
        this.getCurrentCategory.id === 267 &&
        checkFormModel(this.business)
      ) {
        this.disabled = true;
        this.business.price = this.getCurrentCategory.price;
        let formdata = getFormData(this.business);
        formdata.append("user_phone", this.business.phone_number);
        let payment_method =
          this.$store.state.paymentName == " "
            ? "kbz"
            : this.$store.state.paymentName;
        formdata.append("payment_method", payment_method);
        let cdata = {
          category_id: this.getCurrentCategory.id,
          amount: this.getCurrentCategory.price,
          category_name: "business",
          // promocode: this.promocode,
          phone_number: this.business.phone_number,
          payment_name: this.actionBtnMethod,
        };
        await makePayment([
          "1875/businessNaming/horoscope",
          formdata,
          cdata,
          this.$store.state.backendUrl,
          this.getCurrentCategory.price,
        ])
          .then((data) => {
            // console.log(data);
            if (data.status == 200) {
              // console.log(data);
              this.$store.state.order_id = data.order_id;
              this.$store.state.amount = data.amount;
              if (!data.payment_status) {
                this.$router.push({
                  name: "paymentPortalV3",
                });
              } else {
                this.$router.push({
                  name: "PaymentSuccess",
                  params: { payment: "PWA BPPay" },
                });
              }
              moment(this.birth_date).format("YYYY-MM-DD");
            } else {
              this.$swal("Oops", "Something went wrong!", "error");
            }
          })
          .catch(() => {
            this.$swal("Oops", "Something went wrong!", "error");
          });
      } else {
        this.$swal("Oops", "You need to fill all fields", "error");
      }
    },

    async makePayment() {
      if (this.child.birth_time == "") {
        this.child.birth_time = " ";
      }
      if (this.oneyear.birth_time == "") {
        this.oneyear.birth_time = " ";
      }

      if (this.getCurrentCategory.id === 275 && checkFormModel(this.love)) {
        this.disabled = true;
        this.love.price = this.getCurrentCategory.price;
        let formdata = getFormData(this.love);
        formdata.append("user_phone", this.love.phone_number);
        let payment_method =
          this.$store.state.paymentName == " "
            ? "kbz"
            : this.$store.state.paymentName;
        formdata.append("payment_method", payment_method);
        let cdata = {
          category_id: this.getCurrentCategory.id,
          amount: this.getCurrentCategory.price,
          category_name: "love",
          payment_name: this.actionBtnMethod,
          phone_number: this.love.phone_number,
          // promocode: this.promocode,
        };
        await makePayment([
          "1875/love/horoscope",
          formdata,
          cdata,
          this.$store.state.backendUrl,
          this.getCurrentCategory.price,
        ])
          .then((data) => {
            // console.log(data);
            if (data.status == 200) {
              // console.log(data);
              this.$store.state.order_id = data.order_id;
              this.$store.state.amount = data.amount;
              if (!data.payment_status) {
                this.$router.push({
                  name: "paymentPortal",
                });
              } else {
                this.$router.push({
                  name: "PaymentSuccess",
                  params: { payment: "mpitesan" },
                });
              }
            } else {
              this.$swal("Oops", "Something went wrong!", "error");
            }
          })
          .catch(() => {
            this.$swal("Oops", "Something went wrong!", "error");
          });
      } else if (
        this.getCurrentCategory.id === 11 &&
        checkFormModel(this.child)
      ) {
        this.disabled = true;
        this.child.price = this.getCurrentCategory.price;
        let formdata = getFormData(this.child);
        formdata.append("user_phone", this.child.phone_number);
        let payment_method =
          this.$store.state.paymentName == " "
            ? "kbz"
            : this.$store.state.paymentName;
        formdata.append("payment_method", payment_method);
        let cdata = {
          category_id: this.getCurrentCategory.id,
          amount: this.getCurrentCategory.price,
          category_name: "child",
          // promocode: this.promocode,
          phone_number: this.child.phone_number,
          payment_name: this.actionBtnMethod,
        };
        await makePayment([
          "1875/childNaming/horoscope",
          formdata,
          cdata,
          this.$store.state.backendUrl,
          this.getCurrentCategory.price,
        ])
          .then((data) => {
            // console.log(data);
            if (data.status == 200) {
              // console.log(data);
              this.$store.state.order_id = data.order_id;
              this.$store.state.amount = data.amount;
              if (!data.payment_status) {
                this.$router.push({
                  name: "paymentPortal",
                });
              } else {
                this.$router.push({
                  name: "PaymentSuccess",
                  params: { payment: "mpitesan" },
                });
              }
            } else {
              this.$swal("Oops", "Something went wrong!", "error");
            }
          })
          .catch(() => {
            this.$swal("Oops", "Something went wrong!", "error");
          });
      } else if (
        this.getCurrentCategory.id === 10 &&
        checkFormModel(this.oneyear)
      ) {
        this.disabled = true;
        if (this.oneyear.phone_number == "09251569702") {
          this.oneyear.price = 1;
        } else {
          this.oneyear.price = this.getCurrentCategory.price;
        }
        let formdata = getFormData(this.oneyear);
        formdata.append("user_phone", this.oneyear.phone_number);
        let payment_method =
          this.$store.state.paymentName == " "
            ? "kbz"
            : this.$store.state.paymentName;
        formdata.append("payment_method", payment_method);
        let cdata = {
          category_id: this.getCurrentCategory.id,
          amount: this.getCurrentCategory.price,
          category_name: "oneyear",
          // promocode: this.promocode,
          phone_number: this.oneyear.phone_number,
          payment_name: this.actionBtnMethod,
        };
        await makePayment([
          "1875/oneyear/horoscope",
          formdata,
          cdata,
          this.$store.state.backendUrl,
          this.getCurrentCategory.price,
        ])
          .then((data) => {
            // console.log(data);
            if (data.status == 200) {
              // console.log(data);
              this.$store.state.order_id = data.order_id;
              this.$store.state.amount = data.amount;
              if (!data.payment_status) {
                this.$router.push({
                  name: "paymentPortal",
                });
              } else {
                this.$router.push({
                  name: "PaymentSuccess",
                  params: { payment: "mpitesan" },
                });
              }
            } else {
              this.$swal("Oops", "Something went wrong!", "error");
            }
          })
          .catch(() => {
            this.$swal("Oops", "Something went wrong!", "error");
          });
      } else if (
        this.getCurrentCategory.id === 267 &&
        checkFormModel(this.business)
      ) {
        this.disabled = true;
        this.business.price = this.getCurrentCategory.price;
        let formdata = getFormData(this.business);
        formdata.append("user_phone", this.business.phone_number);
        let payment_method =
          this.$store.state.paymentName == " "
            ? "kbz"
            : this.$store.state.paymentName;
        formdata.append("payment_method", payment_method);
        let cdata = {
          category_id: this.getCurrentCategory.id,
          amount: this.getCurrentCategory.price,
          category_name: "business",
          // promocode: this.promocode,
          phone_number: this.business.phone_number,
          payment_name: this.actionBtnMethod,
        };
        await makePayment([
          "1875/businessNaming/horoscope",
          formdata,
          cdata,
          this.$store.state.backendUrl,
          this.getCurrentCategory.price,
        ])
          .then((data) => {
            // console.log(data);
            if (data.status == 200) {
              // console.log(data);
              this.$store.state.order_id = data.order_id;
              this.$store.state.amount = data.amount;
              if (!data.payment_status) {
                this.$router.push({
                  name: "paymentPortal",
                });
              } else {
                this.$router.push({
                  name: "PaymentSuccess",
                  params: { payment: "mpitesan" },
                });
              }
              moment(this.birth_date).format("YYYY-MM-DD");
            } else {
              this.$swal("Oops", "Something went wrong!", "error");
            }
          })
          .catch(() => {
            this.$swal("Oops", "Something went wrong!", "error");
          });
      } else {
        this.$swal("Oops", "You need to fill all fields", "error");
      }
    },

    precreate() {
      if (this.child.birth_time == "") {
        this.child.birth_time = " ";
      }
      if (this.oneyear.birth_time == "") {
        this.oneyear.birth_time = " ";
      }

      if (this.getCurrentCategory.id === 275 && checkFormModel(this.love)) {
        this.disabled = true;
        HTTP.defaults.headers.Authorization = `Bearer ${this.getUserToken}`;
        HTTP.post("subscription/precreate", {
          category_id: this.getCurrentCategory.id,
          amount: this.getCurrentCategory.price,
          category_name: "love",
        })
          .then((response) => {
            const res = response.data;
            this.love.order_id = res.order_id;
            this.love.user_id = res.user_id;
            this.love.price = this.getCurrentCategory.price;
            let formdata = getFormData(this.love);
            formdata.set("price", res.amount);
            formdata.append(
              "user_phone",
              this.getResponse.customer_info.msisdn
            );
            Horo.post("1875/love/horoscope", formdata).then((response) => {
              this.res_status = response.data.status;
              this.kbzpay.startPay(
                res.prepay_id,
                res.order_info,
                res.sign_app,
                ({ resultCode, lang }) => {
                  if (resultCode == 1) {
                    if (lang == "en") {
                      this.$swal("Success", "Your Purchase Success", "success");
                      this.$router.push({ name: "home" });
                    }
                    if (lang == "my") {
                      this.$swal("အောင်မြင်ပါသည်", "ဝယ်ယူပြီးပါပြီ", "success");
                      this.$router.push({ name: "home" });
                    }
                  }
                  if (resultCode == 2) {
                    if (lang == "en") {
                      this.$swal("Failed", "Your Purchase Failed", "error");
                      this.disabled = false;
                    }
                    if (lang == "my") {
                      this.$swal("မအောင်မြင်ပါ", "ထပ်မံကြိုးစားပေးပါ", "error");
                      this.disabled = false;
                    }
                  }
                },
                () => {
                  this.$swal("Failed", "Your Purchase Failed", "error");
                  this.disabled = false;
                }
              );
            });
          })
          .catch((error) => {
            console.log(error);
            this.message = "Something went wrong";
          });
      } else if (
        this.getCurrentCategory.id === 11 &&
        checkFormModel(this.child)
      ) {
        this.disabled = true;
        HTTP.defaults.headers.Authorization = `Bearer ${this.getUserToken}`;
        HTTP.post("subscription/precreate", {
          category_id: this.getCurrentCategory.id,
          amount: this.getCurrentCategory.price,
          category_name: "child",
        })
          .then((response) => {
            const res = response.data;
            this.child.order_id = res.order_id;
            this.child.user_id = res.user_id;
            this.child.price = this.getCurrentCategory.price;
            let formdata = getFormData(this.child);
            formdata.set("price", res.amount);
            formdata.append(
              "user_phone",
              this.getResponse.customer_info.msisdn
            );
            Horo.post("1875/childNaming/horoscope", formdata).then(
              (response) => {
                this.res_status = response.data.status;
                this.kbzpay.startPay(
                  res.prepay_id,
                  res.order_info,
                  res.sign_app,
                  ({ resultCode, lang }) => {
                    if (resultCode == 1) {
                      if (lang == "en") {
                        this.$swal(
                          "Success",
                          "Your Purchase Success",
                          "success"
                        );
                        this.$router.push({ name: "home" });
                      }
                      if (lang == "my") {
                        this.$swal(
                          "အောင်မြင်ပါသည်",
                          "ဝယ်ယူပြီးပါပြီ",
                          "success"
                        );
                        this.$router.push({ name: "home" });
                      }
                    }
                    if (resultCode == 2) {
                      if (lang == "en") {
                        this.$swal("Failed", "Your Purchase Failed", "error");
                        this.disabled = false;
                      }
                      if (lang == "my") {
                        this.$swal(
                          "မအောင်မြင်ပါ",
                          "ထပ်မံကြိုးစားပေးပါ",
                          "error"
                        );
                        this.disabled = false;
                      }
                    }
                  },
                  () => {
                    this.$swal("Failed", "Your Purchase Failed", "error");
                    this.disabled = false;
                  }
                );
              }
            );
          })
          .catch((error) => {
            console.log(error);
            this.message = "Something went wrong";
          });
      } else if (
        this.getCurrentCategory.id === 10 &&
        checkFormModel(this.oneyear)
      ) {
        this.disabled = true;
        HTTP.defaults.headers.Authorization = `Bearer ${this.getUserToken}`;
        HTTP.post("subscription/precreate", {
          category_id: this.getCurrentCategory.id,
          amount: this.getCurrentCategory.price,
          category_name: "oneyear",
        })
          .then((response) => {
            const res = response.data;
            this.oneyear.order_id = res.order_id;
            this.oneyear.user_id = res.user_id;
            this.oneyear.price = this.getCurrentCategory.price;
            let formdata = getFormData(this.oneyear);
            formdata.set("price", res.amount);
            formdata.append(
              "user_phone",
              this.getResponse.customer_info.msisdn
            );
            Horo.post("1875/oneyear/horoscope", formdata).then((response) => {
              this.res_status = response.data.status;
              this.kbzpay.startPay(
                res.prepay_id,
                res.order_info,
                res.sign_app,
                ({ resultCode, lang }) => {
                  if (resultCode == 1) {
                    if (lang == "en") {
                      this.$swal("Success", "Your Purchase Success", "success");
                      this.$router.push({ name: "home" });
                    }
                    if (lang == "my") {
                      this.$swal("အောင်မြင်ပါသည်", "ဝယ်ယူပြီးပါပြီ", "success");
                      this.$router.push({ name: "home" });
                    }
                  }
                  if (resultCode == 2) {
                    if (lang == "en") {
                      this.$swal("Failed", "Your Purchase Failed", "error");
                      this.disabled = false;
                    }
                    if (lang == "my") {
                      this.$swal("မအောင်မြင်ပါ", "ထပ်မံကြိုးစားပေးပါ", "error");
                      this.disabled = false;
                    }
                  }
                },
                () => {
                  this.$swal("Failed", "Your Purchase Failed", "error");
                  this.disabled = false;
                }
              );
            });
          })
          .catch((error) => {
            console.log(error);
            this.message = "Something went wrong";
          });
      } else if (
        this.getCurrentCategory.id === 267 &&
        checkFormModel(this.business)
      ) {
        this.disabled = true;
        HTTP.defaults.headers.Authorization = `Bearer ${this.getUserToken}`;
        HTTP.post("subscription/precreate", {
          category_id: this.getCurrentCategory.id,
          amount: this.getCurrentCategory.price,
          category_name: "business",
        })
          .then((response) => {
            const res = response.data;
            this.business.order_id = res.order_id;
            this.business.user_id = res.user_id;
            this.business.price = this.getCurrentCategory.price;
            let formdata = getFormData(this.business);
            formdata.set("price", res.amount);
            formdata.append(
              "user_phone",
              this.getResponse.customer_info.msisdn
            );
            Horo.post("1875/businessNaming/horoscope", formdata).then(
              (response) => {
                this.res_status = response.data.status;
                this.kbzpay.startPay(
                  res.prepay_id,
                  res.order_info,
                  res.sign_app,
                  ({ resultCode, lang }) => {
                    if (resultCode == 1) {
                      if (lang == "en") {
                        this.$swal(
                          "Success",
                          "Your Purchase Success",
                          "success"
                        );
                        this.$router.push({ name: "home" });
                      }
                      if (lang == "my") {
                        this.$swal(
                          "အောင်မြင်ပါသည်",
                          "ဝယ်ယူပြီးပါပြီ",
                          "success"
                        );
                        this.$router.push({ name: "home" });
                      }
                    }
                    if (resultCode == 2) {
                      if (lang == "en") {
                        this.$swal("Failed", "Your Purchase Failed", "error");
                        this.disabled = false;
                      }
                      if (lang == "my") {
                        this.$swal(
                          "မအောင်မြင်ပါ",
                          "ထပ်မံကြိုးစားပေးပါ",
                          "error"
                        );
                        this.disabled = false;
                      }
                    }
                  },
                  () => {
                    this.$swal("Failed", "Your Purchase Failed", "error");
                    this.disabled = false;
                  }
                );
              }
            );
          })
          .catch((error) => {
            console.log(error);
            this.message = "Something went wrong";
          });
      } else {
        this.$swal("Oops", "You need to fill all fields", "error");
      }
    },
  },
};
</script>

<style scoped>
input[type="radio"] {
  display: inline;
  margin-top: 8px;
  margin-left: 3px;
}
</style>
